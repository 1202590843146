import React from 'react';
import { Helmet } from 'react-helmet';

const HubSpotHealthcareForm = ({ target, region, portalId, formId }) => (
  <div>
    <Helmet>
      <script
        type="text/javascript"
        src="//js.hsforms.net/forms/embed/v2.js"
        charset="utf-8"
        onLoad={`
          let data = {
            region: "na1",
            portalId: "23801619",
            formId: "aba5dd13-fa2e-4be5-acd1-4435cbd6dff1",
            locale: 'en',
            translations: {
              en: {
                required: "Please fill in this field!",
                submissionErrors:{ 
                  MISSING_REQUIRED_FIELDS: "Please fill in all required fields!" 
                }
              }
            },
            onFormReady: function() { // Add this function
              const formLoadedEvent = new Event('formLoaded');
              window.dispatchEvent(formLoadedEvent);
            },
          };

          window.hbspt.forms.create({
            target: '#HSHealthcareFormTop',
            formId: 'HSHealthcareFormTop',
            ...data
          });

          window.hbspt.forms.create({
            target: '#HSHealthcareFormBottom',
            formId: 'HSHealthcareFormBottom',
            ...data
          });

          window.hbspt.forms.create({
            target: '#HSHealthcareFormPopup',
            formId: 'HSHealthcareFormPopup',
            ...data
          });

          window.addEventListener('message', event => {
            if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
                console.log('Healthcare Form Submitted!');
                const { submissionValues } = event.data.data;
                window.open('https://meetings.hubspot.com/my-roller?firstName=' + submissionValues.firstname + '&lastName=' + submissionValues.lastname + '&email=' + submissionValues.email);
                if (window.dataLayer) {
                  window.dataLayer.push({
                    event: 'healthcare book a demo',
                    eventName: 'web - book a demo healthcare',
                    eventCategory: 'submit form',
                    eventAction: 'book a demo form'
                  })
                }
            }
          });
          
        `}
      />
    </Helmet>
  </div>
);

export default HubSpotHealthcareForm;
