import React, { useRef, useState, useEffect } from 'react';
import HubSpotHealthcareForm from './HubSpotHealthcareForm';
import tw from 'twin.macro';
import Spinner from './Spinner';

const ModalForHubspotHealthcareForm = ({ isOpen, handleClose }) => {
  const modalRef = useRef();
  const [isLoading, setIsLoading] = useState(true); // initial loading state

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      handleClose();
    }
  };

  // Use an effect to listen to the 'formLoaded' event
  useEffect(() => {
    const handleFormLoaded = () => setIsLoading(false); // hide spinner when form is loaded

    window.addEventListener('formLoaded', handleFormLoaded);

    // Clean up event listener when component unmounts
    return () => {
      window.removeEventListener('formLoaded', handleFormLoaded);
    };
  }, []);

  // Use an effect to reset the loading state when the modal closes
  useEffect(() => {
    if (!isOpen) {
      setIsLoading(true); // reset loading state
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div css={tw`fixed top-0 left-0 w-full h-full bg-black bg-opacity-30 flex justify-center items-center z-50`} onClick={handleClickOutside}>
      <div ref={modalRef} css={tw`bg-white p-10 rounded w-4/5 max-w-lg relative`}>
        {isLoading && <Spinner />} {/* show Spinner while loading */}
        <div css={tw`flex justify-center items-center top-2 right-2 pb-10`}>
          {!isLoading && <h1 css={tw`text-xl text-center flex-grow`}>Book a Demo</h1>}
          {!isLoading && <button onClick={handleClose} css={tw`text-gray-500 hover:text-black transition-all`}>X</button>}
        </div>
        <div id="HSHealthcareFormPopup">
          <HubSpotHealthcareForm />
        </div>
      </div>
    </div>
  );
};

export default ModalForHubspotHealthcareForm;
