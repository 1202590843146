import React, { useState, useEffect } from 'react'
import tw from 'twin.macro'
import { useForm } from 'react-hook-form'

import { graphql, Link, useStaticQuery } from 'gatsby'
import { CopySMStyle, CopyStyle } from './UI'
import { GatsbyImage } from 'gatsby-plugin-image'

import wordmark from '../images/staffy-footer-wordmark.svg'
import newFooterLogo from '../images/new-footer-logo-cropped.png'
import appStore from '../images/app-store.svg'
import googlePlay from '../images/google-play.svg'
import arrow from '../images/icons/footer-form-submit-arrow.svg'
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { setUtmParams, areUtmParamsAvailable } from '../utils'

const Footer = () => {
  const globalQuery = useStaticQuery(graphql`
    {
      contentfulGlobalSettings(pageId: { eq: "Globals" }) {
        socials {
          logo {
            title
            gatsbyImageData
          }
          url
        }
        iosDownloadLink
        androidDownloadLink
      }
    }
  `)

  const globalData = globalQuery.contentfulGlobalSettings

  const [formSubmitted, setFormSubmitted] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const dispatch = useDispatch();

  const utmParams = useSelector((state) => state.utmParams);
  useEffect(() => {
    if (!areUtmParamsAvailable(utmParams)) {
      dispatch({ type: "SAVE_UTM_PARAMS", payload: setUtmParams() });
    }
  }, [dispatch])

  const onSubmit = (data) => {
    const headers = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded',
    })

    const options = {
      method: 'POST',
      headers: headers,
      body: new URLSearchParams({
        email: data.email,
      }),
    }

    fetch('https://api.staffy.com/api/newsletter-subscription', options)
      .then((response) => response.json())
      .then((response) => {
        if (response === 'success') {
          setFormSubmitted(true);
          if (window.dataLayer) {
            window.dataLayer.push({
              event: 'healthcare submits email to join staffy newsletter',
              eventName: 'web - newsletter signup form healthcare',
              eventCategory: 'submit form',
              eventAction: 'newsletter form'
            })
          }

        }
      })

    const params = {
      ...utmParams,
      email: data.email,
    }

    const optionsZapier = {
      method: 'POST',
      body: JSON.stringify(params),
    }

    fetch(
      'https://hooks.zapier.com/hooks/catch/4090899/bpsrrjo',
      optionsZapier
    ).then((response) => response.json())
  }

  return (
    <section
      id="footer"
      tw="grid grid-cols-4 gap-x-6 mx-7 pt-12 pb-5 lg:(max-w-screen-lg grid-cols-12 mx-auto pt-11)"
    >
      {/* Logo */}
      <div tw="col-span-full lg:(col-span-12)">
        <img width='150px' height='50px' loading='lazy' src={newFooterLogo} tw="mb-7 w-[150px]" alt="Staffy Logo" />
      </div>
      <div css={[CopyStyle, tw`col-span-full lg:(col-span-4)`]}>
        <b>Our Solutions</b>
        <a css={[tw`block py-4 pt-8`]} href="https://staffy.com" target="_blank">
          Marketplace
        </a>
        <hr tw="border-black" />
        <a css={[tw`block py-4`]} href="https://salus.staffy.com" target="_blank">
          Credential Management
        </a>
        <hr tw="border-black mb-[20px]" />
      </div>
      {/* Form */}
      {/* <div tw="col-span-full lg:(col-span-4)">
        <p css={[CopyStyle, tw`mb-6`]}>
          Join our newsletter to stay up to date on features and releases
        </p>
        <form
          onSubmit={handleSubmit(onSubmit)}
          css={[tw`mb-14 lg:(mb-6)`, formSubmitted && tw`hidden`]}
        >
          <div tw="relative flex items-center justify-between border border-black w-full mb-2">
            <input
              css={[CopyStyle, tw`px-5 py-4 flex-grow`]}
              id="email-input"
              name="Email"
              placeholder="Email"
              {...register('email', {
                required: true,
                pattern:
                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              })}
            />
            <div tw="relative flex items-center h-14">
              <img src={arrow} tw="w-16 px-3 py-1" alt="Arrow right" />
              <input
                type="submit"
                value=""
                tw="absolute w-full h-full top-0 opacity-0"
              />
            </div>
          </div>
          {errors.email && (
            <span css={[CopyStyle, tw`text-red-500`]}>
              This field is required
            </span>
          )}
        </form>
        <div
          css={[
            tw`hidden px-0 py-4 text-green-600`,
            formSubmitted && tw`block`,
          ]}
        >
          You've been subscribed to our Newsletter, thanks!
        </div>
      </div> */}
      {/* Links 1 */}
      <div css={[CopyStyle, tw`col-span-2 lg:(col-start-7 col-span-2)`]}>
        <Link to="/find-talent" tw="block pb-4">
          Find Talent
        </Link>
        <hr tw="border-black" />
        <Link to="/start-earning" tw="block py-4">
          Start Earning
        </Link>
        <hr tw="border-black" />
        <Link to="/about" tw="block py-4">
          About
        </Link>
        <hr tw="border-black" />
        <Link to="/find-talent#book-a-demo" tw="block pt-4">
          Book a demo
        </Link>
        {/* Socials */}
        <div tw="block mt-[32px]">
          {globalData.socials &&
            globalData.socials.map((social, index) => {
              return (
                <a
                  key={`social-${index}`}
                  href={social.url}
                  target="_blank"
                  rel="noreferrer"
                  tw="w-4 h-4 inline-block mr-3"
                >
                  <GatsbyImage
                    tw="w-full"
                    image={social.logo.gatsbyImageData}
                    alt={social.logo.title}
                  />
                </a>
              )
            })}
        </div>
      </div>
      {/* Links 2 */}
      <div css={[CopyStyle, tw`col-span-2 lg:(col-start-9 col-span-2)`]}>
        <Link to="/blog" tw="block pb-4">
          Blog
        </Link>
        <hr tw="border-black" />
        <Link to="/contact" tw="block py-4">
          Contact
        </Link>
        <hr tw="border-black" />
        <Link to="/hospitality" tw="block py-4">
          Hospitality
        </Link>
      </div>
      {/* App links */}
      <div
        css={[
          CopyStyle,
          tw`col-span-2 mt-10 lg:(col-start-11 col-span-2 mt-0)`,
        ]}
      >
        <div tw="mb-4">Mobile apps</div>
        {globalData.androidDownloadLink && (
          <a
            href={globalData.iosDownloadLink}
            target="_blank"
            rel="noreferrer"
            tw="block mb-2.5 w-[123px]"
          >
            <img loading='lazy' src={appStore} alt="App store link" />
          </a>
        )}
        {globalData.androidDownloadLink && (
          <a
            href={globalData.androidDownloadLink}
            target="_blank"
            rel="noreferrer"
            tw="block w-[123px]"
          >
            <img loading='lazy' src={googlePlay} alt="Google play link" />
          </a>
        )}
      </div>
      {/* Copyrights and stuff */}
      <div
        css={[
          CopySMStyle,
          tw`text-[#A6ABB0] col-span-full mt-20 flex flex-col lg:(col-span-12 flex-row justify-between mt-24)`,
        ]}
      >
        <div tw="order-2 mt-5 lg:(order-1 mt-0)">
          2024 Staffy Health. All rights reserved.
        </div>
        <div tw="order-1 lg:(order-2)">
          <a href="/privacy-policy">Privacy Policy</a>
          <a href="/services-agreement" tw="ml-7">
            Terms of Service
          </a>
        </div>
      </div>
    </section>
  )
}

export default Footer
